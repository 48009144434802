import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import routes from "../../routes";
import { useSelector } from "react-redux";
import { selectUser } from "../auth/authSlice";

const S = {
    Footer: styled.footer`
      @media print {
        display: none;
      }
      
      background-color: #2d3e50;
      color: #b6b6b6;
      padding: 32px 0px 12px 0px;
    `,
    Link: styled(Link)`
      color: #e3e3e3;
      font-weight: 600;

      :hover {
        color: #ffffff;
      }
    `,
}

const Footer = () => {
  const user = useSelector(selectUser)

  return (
    <S.Footer>
        <Container>
            { routes
                .filter(route => route.inFooter)
                .filter(route => {
                  if (route.organization) {
                      return user?.isSuperuser
                  }
                  if (route.isAuth) {
                      return !!user
                  }
                  return true
              })
                .map((route, index, arr) => (
                    <span key={index}>
                        <S.Link to={route.path}>{route.title}</S.Link>
                        {(index + 1) < arr.length ? ' / ' : ''}
                    </span>
                ))
            }

            <br/>
            <br/>

            <p>
                Cyber Crime Investigation, Intelligence & Digital Forensics<br/>
                ©UrlForensics.com. All images and content are copyright of Digitpol and can not be used,
                replicated or reproduced without written permission. 2013 - 2021
            </p>
        </Container>
    </S.Footer>
  )
}

export default Footer
